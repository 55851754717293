<template>
  <v-list-item>
    <v-container grid-list-xl fluid>
      <AppTitleBar>
        <template v-slot:title>{{ $t('labels.settings') }}</template>
        <template slot="actions">
          <InviteUser :onSubmit="inviteUser" />
        </template>
      </AppTitleBar>
      <v-loading v-if="loading" />
      <v-row v-else>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <OrgAvatar :currentOrg="currentOrg" />
            </v-col>
            <v-col cols="12">
              <OrganizationPreferences :currentOrg="currentOrg" />
            </v-col>
            <v-col cols="12">
              <Invitations />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex" cols="12" md="6">
          <v-row>
            <v-col class="d-flex" cols="12">
              <UsersList />
            </v-col>
            <v-col class="d-flex" cols="12">
              <GroupsList :users="orgUsers" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { AppTitleBar, VLoading } from '@argon/app/components'
import { NAMESPACE } from '@argon/iam/organizations/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE as ATTR_NAMESPACE } from '@argon/iam/attributions/store'
import { GroupsList } from '@argon/iam/groups/components'
import { OrgAvatar, Invitations, OrganizationPreferences } from '@argon/iam/organizations/components'
import { InviteUser } from '@argon/iam/components'
import organizationPerimeter from '@argon/iam/organizations/perimeters'
import UsersList from '../components/UsersList'

export default {
  name: 'SimplOrganizationSettings',
  perimeters: [organizationPerimeter],
  components: {
    AppTitleBar,
    VLoading,
    InviteUser,
    OrgAvatar,
    GroupsList,
    UsersList,
    Invitations,
    OrganizationPreferences
  },
  props: {
    orgRef: { type: String }
  },
  data: () => ({ loading: true }),
  computed: {
    ...mapGetters(NAMESPACE, ['currentOrg', 'users']),
    ...mapState(ME_NAMESPACE, ['profile']),
    orgUsers() {
      return this.users.map((x) => x.user)
    }
  },
  methods: {
    ...mapActions(ATTR_NAMESPACE, ['listAttributions']),
    ...mapActions(NAMESPACE, ['getOrganization', 'inviteUser']),
    setWarning(message) {
      this.warning = true
      this.warningMessage = message
    },
    fetchData() {
      this.loading = true
      Promise.all([this.getOrganization(1), this.listAttributions()]).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
