<template>
  <VWidget content-class="pa-0 v-userlist" css-class="flex-grow-1">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:header-actions>
      <v-btn icon small @click="showSearch = !showSearch">
        <v-icon small>{{ $vuetify.icons.values.search }} </v-icon>
      </v-btn>
      <v-btn icon small @click="createUser">
        <v-icon small>{{ $vuetify.icons.values.add }} </v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-expand-transition>
        <SingleSearchFilter
          transition="slide-y-transition"
          class-name="px-3 pt-0"
          v-if="showSearch"
          :context="context"
          :fields="searchFields"
        />
      </v-expand-transition>
      <v-divider v-show="showSearch" />
      <v-data-table
        flat
        :headers="headers"
        :items="members"
        :loading="loading"
        :server-items-length="usersCount"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
        hide-default-header
        class="elevation-0"
      >
        <template v-slot:item="{ item }">
          <UserLine
            :org-user="item"
            :editOrgUser="add"
            :destroy="destroy"
            :editProfile="editProfile"
            :editAccount="editAccount"
            :editPassword="editPassword"
            :disable="disable"
            :enable="enable"
          />
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { create } from 'vue-modal-dialogs'
import { mapActions, mapState } from 'vuex'
import { ConfirmDeleteUserDialog, ConfirmDisableUserDialog, ConfirmEnableUserDialog } from '@argon/iam/users/components'
import { VWidget, SingleSearchFilter } from '@argon/app/components'
import groupPerimeter from '@argon/iam/groups/perimeters'
import { Datatable } from '@argon/app/mixins'
import { NAMESPACE } from '@argon/iam/organizations/store'
import { NAMESPACE as USER_NAMESPACE } from '@argon/iam/users/store'
import { NAMESPACE as ATTR_NAMESPACE } from '@argon/iam/attributions/store'
import UserLine from './UserLine'

const confirmDeleteAction = create(ConfirmDeleteUserDialog)
const confirmDisableAction = create(ConfirmDisableUserDialog)
const confirmEnableAction = create(ConfirmEnableUserDialog)

export default {
  name: 'UsersList',
  components: { VWidget, UserLine, SingleSearchFilter },
  mixins: [Datatable],
  perimeters: [groupPerimeter],
  props: {},
  data: () => ({
    headers: [{ value: 'icon' }, { value: 'display' }, { value: 'attributionName' }, { value: 'actions' }],
    filterString: null,
    searchFields: [
      { label: 'display', type: 'text' },
      { label: 'email', type: 'text' }
    ],
    loading: true,
    showSearch: false,
    context: USER_NAMESPACE
  }),
  computed: {
    ...mapState(ATTR_NAMESPACE, ['attributions']),
    ...mapState(NAMESPACE, ['users', 'usersCount', 'currentOrg', 'userFilterObject']),
    members() {
      return this.users.map((match) => {
        const attribution = this.attributions.find((attr) => attr.value === match.attribution)
        return {
          ...match,
          attributionName: attribution ? attribution.name : 'LEAST'
        }
      })
    }
  },
  methods: {
    ...mapActions(USER_NAMESPACE, ['listUsers', 'clearUsers', 'deleteUser', 'patchAccount']),
    ...mapActions(NAMESPACE, [
      'updateOrganizationUser',
      'deleteOrganizationUser',
      'listCurrentOrgUsers',
      'clearCurrentOrgUsers',
      'inviteUser',
      'setUserFilters'
    ]),
    add(payload) {
      return this.updateOrganizationUser({
        ...payload,
        orgId: this.currentOrg.id,
        pager: this.pager,
        filters: this.filterString
      })
    },
    destroy(orgUser) {
      confirmDeleteAction().then((res) => {
        if (res) {
          this.deleteUser({
            userId: orgUser.user_id,
            pager: this.pager,
            filters: this.filterString
          })
        }
      })
    },
    editProfile(orgUser) {
      this.$router.push({ name: 'orgUserProfile', params: { userId: orgUser.user_id } })
    },
    editAccount(orgUser) {
      this.$router.push({ name: 'orgUserAccount', params: { userId: orgUser.user_id } })
    },
    editPassword(orgUser) {
      this.$router.push({ name: 'orgUserPassword', params: { userId: orgUser.user_id } })
    },
    createUser() {
      this.$router.push({ name: 'orgUserProfile', params: { userId: 'new' } })
    },
    disable(orgUser) {
      confirmDisableAction().then((res) => {
        if (res) {
          this.patchAccount({ userId: orgUser.user_id, data: { active: false } }).then(() => {
            this.fetchData()
          })
        }
      })
    },
    enable(orgUser) {
      confirmEnableAction().then((res) => {
        if (res) {
          this.patchAccount({ userId: orgUser.user_id, data: { active: true } }).then(() => {
            this.fetchData()
          })
        }
      })
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.listCurrentOrgUsers({ pager: this.pager })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.loading = false
          })
      })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
<style>
.v-userlist .v-datatable__actions__select {
  display: none;
}
</style>

<i18n>
{
  "en": {
    "title": "@:labels.users"
  },
  "fr": {
    "title": "@:labels.users"
  }
}
</i18n>
