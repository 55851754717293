<template>
  <tr>
    <td class="avatar">
      <v-avatar size="32px">
        <img v-if="orgUser.user.avatar" :src="orgUser.user.avatar" />
        <v-icon v-else-if="orgUser.type === 'group'" small>
          {{ $vuetify.icons.values.users }}
        </v-icon>
        <v-icon v-else small>{{ $vuetify.icons.values.orgUser }}</v-icon>
      </v-avatar>
    </td>
    <td>
      <span :class="{ disabled: !orgUser.account.active, heading: true }">{{ orgUser.display }}</span>
    </td>
    <td>
      <v-row align-center justify-space-between row fill-height>
        <div>{{ $t(`attributions.${orgUser.attributionName}`) }}</div>
        <div></div>
      </v-row>
    </td>
    <td class="actions">
      <v-toolbar dense flat color="transparent">
        <v-menu bottom left min-width="150">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon small>
              <v-icon small v-text="$vuetify.icons.values.options" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editProfile(orgUser)">
              <v-list-item-title>{{ $t('labels.profile') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editAccount(orgUser)">
              <v-list-item-title>{{ $t('labels.account') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editPassword(orgUser)">
              <v-list-item-title>{{ $t('labels.password') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="disable(orgUser)" v-if="orgUser.account.active">
              <v-list-item-title>{{ $t('labels.disable') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="enable(orgUser)" v-else>
              <v-list-item-title>{{ $t('labels.enable') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon small @click="destroy(orgUser)" v-if="canRemove">
          <v-icon small>{{ $vuetify.icons.values.cancel }}</v-icon>
        </v-btn>
      </v-toolbar>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { AttributionField } from '@argon/iam/components'
import { VPopupWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'

export default {
  name: 'UserLine',
  extends: BaseForm,
  components: {
    VPopupWidget,
    FormErrors,
    AttributionField
  },
  props: {
    orgUser: { type: Object, required: true },
    editOrgUser: { type: Function, required: true },
    editProfile: { type: Function, required: true },
    editAccount: { type: Function, required: true },
    editPassword: { type: Function, required: true },
    disable: { type: Function, required: true },
    enable: { type: Function, required: true },
    destroy: { type: Function, required: true },
    editableAttribution: { type: Boolean, default: true },
    allowOwnerRemoval: { type: Boolean, default: false },
    allowSelfRemoval: { type: Boolean, default: false }
  },
  validations: {
    attribution: { required }
  },
  data: () => ({
    attribution: null,
    menu: false,
    loading: false
  }),
  computed: {
    ...mapState(ME_NAMESPACE, ['userId']),
    isSelf() {
      return this.orgUser.user_id === this.userId
    },
    isOwner() {
      let ownerLevel = this.$app.opts.ownerLevel || 50
      return this.orgUser.attribution >= ownerLevel
    },
    canRemoveOwner() {
      if (!this.isOwner) return true
      return this.allowOwnerRemoval
    },
    canRemoveSelf() {
      if (!this.isSelf) return true
      return this.allowSelfRemoval
    },
    canRemove() {
      return this.canRemoveOwner && this.canRemoveSelf
    },
    showSwitch() {
      return this.editableAttribution && !this.isOwner
    },
    attributionErrors() {
      const errors = []
      this.getServerErrors('attribution', errors)
      if (!this.$v.attribution.$dirty) return errors
      !this.$v.attribution.required && errors.push(this.$t('required'))
      return errors
    }
  },
  methods: {
    update() {
      this.resetServerErrors()
      if (!this.$v.attribution.$invalid) {
        this.loading = true
        this.editOrgUser({
          principalIds: [this.orgUser.user_id],
          attribution: this.attribution
        })
          .then(() => {
            this.attribution = null
            this.menu = false
            this.$v.$reset()
          })
          .catch(this.setServerErrors)
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$v.attribution.$touch()
      }
    }
  },
  created() {
    this.resetServerErrors()
    this.attribution = this.orgUser.attribution
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-toolbar__content {
  padding: 0 4px 0 0 !important;

  .v-btn--icon {
    margin-right: 0;
    margin-left: 2px;
  }
}

span.disabled {
  text-decoration: line-through;
}

.avatar {
  vertical-align: center;
  width: 32px;
}

.actions {
  width: 50px;
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "changeAttribution": "Change Attribution",
    "required": "@:labels.required",
    "save": "@:labels.save"
  },
  "fr": {
    "changeAttribution": "Changer l'attribution",
    "required": "@:labels.required",
    "save": "@:labels.save"
  }
}
</i18n>
