import { render, staticRenderFns } from "./UserLine.vue?vue&type=template&id=95c3bf12&scoped=true&"
import script from "./UserLine.vue?vue&type=script&lang=js&"
export * from "./UserLine.vue?vue&type=script&lang=js&"
import style0 from "./UserLine.vue?vue&type=style&index=0&id=95c3bf12&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c3bf12",
  null
  
)

/* custom blocks */
import block0 from "./UserLine.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VBtn,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VToolbar})
